import axios from 'axios';
import { MDBBtn } from 'mdb-react-ui-kit';
import React,{useState, useRef} from 'react';


import { backendPathStoring } from '../Constants';
import "../App.css";
import {FiUpload} from "react-icons/fi";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";


function Uploader(props) {
    const inputRef = useRef(null);

    const authHeader = useAuthHeader();
    const headers = {
        'Authorization': authHeader
    }

    
    const onFileChange = event => {
        uploadFile(event.target.files);
    };
    
    const uploadFile = (selectedFiles) => {
    
        const formData = new FormData();

        for (let i=0; i<selectedFiles.length; i++) {
            formData.append("file"+String(i), selectedFiles[i]);
        }
        formData.append('amount', selectedFiles.length);
        formData.append('target', props.target.replace("root/", ""));

        console.log(props.target);

        axios.post(backendPathStoring + "upload-file.php" , formData,{
            headers: {
            'content-type': 'multipart/form-data'
            }
        }).then((response) => {
            console.log(response);
            inputRef.current.value = null;
            props.refresh();
        });
    };
    

    return(
        <div className="header-btn" hidden={props.hidden}>
            <div className='file-upload-container'>
                <input style={{display:'none'}} type="file" ref={inputRef} multiple onChange={onFileChange}/>

                <MDBBtn style={{color: 'white'}} onClick={() => inputRef.current.click()}>
                    <FiUpload style={{marginRight:"0.5rem", marginBottom:"3px"}} size={15}/>
                    Hochladen
                </MDBBtn>
            </div>
        </div>
    );
    
  }
  
  export default Uploader;