import React from 'react';
import TopNavbar from './components/Navbar';
import { Routes, Route} from 'react-router-dom';
import AuthOutlet from '@auth-kit/react-router/AuthOutlet'

import './App.css';
import Home from './views/Home';
import { DEV_MODE } from './Constants';
import Login from "./views/Login";
import Users from "./views/Users";
import NewUser from "./views/newUser";
import EditUser from "./views/editUser";
import EditPassword from "./views/editPassword";
import FileTable from "./views/FileTable";
import logo from "./imgs/logo.png";
import NotFound from "./views/notFound";
import NoDir from "./views/noDir";


//disable debug comments
if (!DEV_MODE) {
    console.log = () => {};
}

function App() {
    return (
        <>
            <div className="logo-container" style={{height: "70px"}}>
                <img className="logo" src={logo} alt=""></img>
            </div>

            <Routes>
                <Route element={<AuthOutlet fallbackPath='/login' />}>
                    <Route path='/' element={<Home/>} />
                    <Route path='/storage/:path' element={<FileTable/>} />
                    <Route path='/no-dir/:path' element={<NoDir/>} />
                    <Route path="/new-user" element={<NewUser/>} />
                    <Route path="/edit-user/:id" element={<EditUser/>} />
                    <Route path="/edit-password/:id" element={<EditPassword/>} />
                    <Route path="/users" element={<Users/>} />
                </Route>
                <Route path="/login" element={<Login/>} />
                <Route path="*" element={<NotFound/>} />
            </Routes>
        </>
    );
}

export default App;
