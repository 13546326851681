import React, {useEffect, useState} from 'react';
import Axios from "axios";
import {backendPathAuth} from "../Constants";
import TopNavbar from "../components/Navbar";


function NotFound() {

    const [writePermission, setWritePermission] = useState(false);
    const [readPermission, setReadPermission] = useState(false);


    const loadPermissions = () => {
        Axios.get(`${backendPathAuth}user-permissions.php?`).then((response) => {
            console.log(response.data);
            setWritePermission(response.data === "write");
            setReadPermission(response.data === "read" || response.data === "write");
        });
    }

    useEffect(() => {
        loadPermissions();
    }, []);

    return (
        <>
            <TopNavbar readPermissionInitial={readPermission} writePermissionInitial={writePermission}/>

            <div className="main">
                <p>Die angefragte Seite wurde nicht gefunden.</p>
            </div>
        </>
    );
}

export default NotFound;