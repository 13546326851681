import React, {useEffect, useState} from 'react';
import {MDBBtn, MDBInput} from "mdb-react-ui-kit";
import Axios from "axios";
import {backendPathAuth} from "../Constants";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import {useNavigate} from "react-router-dom";
import {Alert} from "react-bootstrap";
import TopNavbar from "../components/Navbar";


function Login() {
    const signIn = useSignIn();
    const navigate = useNavigate();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [wrongLogin, setWrongLogin] = useState(false);


    const [writePermission, setWritePermission] = useState(false);
    const [readPermission, setReadPermission] = useState(false);


    const loadPermissions = () => {
        Axios.get(`${backendPathAuth}user-permissions.php?`).then((response) => {
            console.log(response.data);
            setWritePermission(response.data === "write");
            setReadPermission(response.data === "read" || response.data === "write");
        });
    }

    const login = () => {
        setSubmitted(true);

        if (!username || !password) {
            return;
        }

        Axios.get(backendPathAuth+"login.php", {
            params: {
                username: username,
                password: password
            }
        }).then((response) => {
            console.log(response.data);
            if ("token" in response.data) {
                setWrongLogin(false);
                navigate("/");
                signIn({
                    auth : {
                        token: response.data.token,
                        type: 'Bearer'
                    },
                    userState: {
                        username: username
                    }
                });
            } else {
                setWrongLogin(true);
            }
        });
    };


    useEffect(() => {
        loadPermissions();
    }, []);

    return (
        <>

            <TopNavbar readPermissionInitial={readPermission} writePermissionInitial={writePermission}/>

            <div className="main">

                <Alert hidden={!submitted || !wrongLogin} variant={"warning"}>
                    Username oder Passwort ist ungültig.
                </Alert>

                <div className="header">
                    <h2>Login</h2>
                </div>
                <div className='input-form w-25'>
                    <MDBInput className="mb-3" label='Username' color="primary" id='form1' type='text' onChange={(event) => {
                        setUsername(event.target.value);
                        setSubmitted(false);
                    }} />

                    <MDBInput className="mb-5" label="Passwort" id="typePassword" type="password" onChange={(event) => {
                        setPassword(event.target.value);
                        setSubmitted(false);
                    }} />

                    <MDBBtn type="submit" disabled={username==="" || password===""} onClick={login}>anmelden</MDBBtn>
                </div>


            </div>
        </>
    );
}

export default Login;