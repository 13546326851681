import React, { useState, useEffect } from "react";
import "../App.css";

import Axios from "axios";
import Select from 'react-select'
import {useNavigate, useSearchParams} from "react-router-dom";
import PasswordChecklist from "react-password-checklist"

import {MDBTextArea, MDBBtn, MDBInput} from "mdb-react-ui-kit";
import {backendPathAuth, backendPathUsers, permissionTypes} from "../Constants";
import TopNavbar from "../components/Navbar";
import logo from "../imgs/logo.png";




function NewUser() {
    const navigate = useNavigate();

    const [permissions, setPermissions] = useState({value: 0, label: "Dateien ansehen"});
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [passwordAgain, setPasswordAgain] = useState("");
    const [saveEnabled, setSaveEnabled] = useState(false);

    const [submitted, setSubmitted] = useState(false);

    const [writePermission, setWritePermission] = useState(false);
    const [readPermission, setReadPermission] = useState(false);



    const checkWritePermission = () => {
        Axios.get(`${backendPathAuth}user-permissions.php?`).then((response) => {
            console.log(response.data);
            if (response.data !== "write") {
                navigate("/login");
            }
            setReadPermission(response.data === "read" || response.data === "write");
            setWritePermission(response.data === "write");
        }).catch(() => { navigate("/login"); });
    }

    const validateTask = () => {
        setSubmitted(true);

        if (!checkCompleteness()) {
            return;
        }

        addUser();
    }

    const checkCompleteness = () => {
        if (username === "" || password === "") {
            console.log("not complete");
            setSaveEnabled(false);
            return false;
        }
        return true;
    };

    const addUser = () => {
        Axios.get(backendPathUsers+"create-user.php", {
            params: {
                username: username,
                password: password,
                permissions: permissions.value,
            }
        }).then(() => {
            navigate("/users");
        });
    };

    useEffect(() => {
        checkWritePermission();
    }, []);

    return (
        <>

            <TopNavbar readPermissionInitial={readPermission} writePermissionInitial={writePermission}/>

            <div className="main">
                <div className="header">
                    <h2>Neuen Benutzer Account hinzufügen</h2>
                </div>

                <div className='input-form'>
                    <MDBInput className="input" label='Username' color="primary" id='form1' type='text' onChange={(event) => {
                        setUsername(event.target.value);
                    }} />
                    {(submitted && username === "")?
                        <p className="validation-label">Username darf nicht leer sein.</p> : null
                    }

                    <MDBInput className="input" label='Passwort' color="primary" id='form1' type='password' onChange={(event) => {
                        setPassword(event.target.value);
                    }} />

                    <MDBInput className="input" label='Passwort wiederholen' color="primary" id='form1' type='password' onChange={(event) => {
                        setPasswordAgain(event.target.value);
                    }} />

                    <PasswordChecklist
                        style={{marginTop: "-20px", marginBottom: "32px", marginLeft: "10px"}}
                        rules={["minLength","capital","number","match"]}
                        minLength={8}
                        value={password}
                        valueAgain={passwordAgain}
                        onChange={(isValid) => {if (isValid) {setSaveEnabled(true)}}}
                        validColor={"#64df2b"}
                        invalidColor={"#eb1515"}
                        iconSize={16}
                        messages={{
                            minLength: "Das Passwort enthält mindestens 8 Zeichen.",
                            number: "Das Passwort enthält eine Zahl.",
                            capital: "Das Passwort enthält einen Großbuchstaben.",
                            match: "Die Passwörter stimmen überein.",
                        }}
                    />


                    <p className="input-label">Nutzerrechte</p>
                    <Select className="input" placeholder={'Auswählen...'} options={permissionTypes} value={permissions} onChange={(event) => {
                        setPermissions({value: event.value, label: event.label});
                    }} />
                </div>

                <div className="footer">
                    <MDBBtn className="btn" type="submit" disabled={!saveEnabled} onClick={validateTask}>Speichern</MDBBtn>
                </div>

            </div>
        </>
    );
}

export default NewUser;