import React, {useEffect, useState} from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {LinkContainer} from 'react-router-bootstrap'
import Axios from "axios";
import {backendPathAuth} from "../Constants";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import useSignOut from 'react-auth-kit/hooks/useSignOut';

import logo from '../imgs/white-logo.png';
import {
    MDBCollapse,
    MDBContainer,
    MDBIcon,
    MDBNavbar,
    MDBNavbarItem, MDBNavbarLink,
    MDBNavbarNav,
    MDBNavbarToggler
} from "mdb-react-ui-kit"; // with import


function TopNavbar(props) {
    const signOut = useSignOut()

    const [writePermission, setWritePermission] = useState(false);
    const [readPermission, setReadPermission] = useState(false);


    useEffect(() => {
        setReadPermission(props.readPermissionInitial);
        setWritePermission(props.writePermissionInitial);
    }, [props]);

    return (
        <>
        <Navbar className="navbar" bg="dark" expand="sm" sticky="top">
            <Container>
                <Navbar.Toggle aria-controls="basic-navbar-nav">
                    <MDBIcon icon='bars' fas />
                </Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto" style={{width: '100%'}}>
                        <LinkContainer to="/" hidden={!readPermission}>
                            <Nav.Link>Dateien</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/users" hidden={!writePermission}>
                            <Nav.Link>Accounts</Nav.Link>
                        </LinkContainer>
                    </Nav>
                    <Nav>
                        <LinkContainer to="/login" hidden={readPermission}>
                            <Nav.Link>Login</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/login" hidden={!readPermission}>
                            <Nav.Link onClick={() => {
                                signOut();
                                setWritePermission(false);
                                setReadPermission(false);
                            }}>Logout</Nav.Link>
                        </LinkContainer>
                    </Nav>
                </Navbar.Collapse>

            </Container>
        </Navbar>
        </>
    );
}

export default TopNavbar;
  