import React, { useState, useEffect } from "react";
import "../App.css";

import Axios from "axios";
import Select from 'react-select'
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import PasswordChecklist from "react-password-checklist"

import {MDBTextArea, MDBBtn, MDBInput} from "mdb-react-ui-kit";
import {backendPathAuth, backendPathUsers, permissionTypes} from "../Constants";
import TopNavbar from "../components/Navbar";
import logo from "../imgs/logo.png";




function EditPassword() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [passwordAgain, setPasswordAgain] = useState("");
    const [saveEnabled, setSaveEnabled] = useState(false);

    const [writePermission, setWritePermission] = useState(false);
    const [readPermission, setReadPermission] = useState(false);



    const checkWritePermission = () => {
        Axios.get(`${backendPathAuth}user-permissions.php?`).then((response) => {
            console.log(response.data);
            if (response.data !== "write") {
                navigate("/login");
            }
            setReadPermission(response.data === "read" || response.data === "write");
            setWritePermission(response.data === "write");
        }).catch(() => { navigate("/login"); });
    }

    const getUser = () => {
        Axios.get(backendPathUsers+"user.php?id="+String(id)).then((response) => {
            if (response.data) {
                if (response.data === "unauthorized") {
                    navigate("/login");
                } else if (response.data.length === 1) {
                    setUsername(response.data[0]["Username"]);
                } else {
                    navigate("/users");
                }
                console.log(response.data)
            }
        });
    };

    const editPassword = () => {
        Axios.get(backendPathUsers+"update-user-password.php", {
            params: {
                id: id,
                password: password,
            }
        }).then(() => {
            navigate("/users");
        });
    };


    useEffect(() => {
        checkWritePermission();
    }, []);

    useEffect(() => {
        getUser();
    }, [id]);

    return (
        <>

            <TopNavbar readPermissionInitial={readPermission} writePermissionInitial={writePermission}/>

            <div className="main">
                <div className="header">
                    <h2>Neues Password für {username} vergeben</h2>
                </div>
                
                <div className='input-form'>

                    <MDBInput className="input" label='Passwort' color="primary" id='form1' type='password' onChange={(event) => {
                        setPassword(event.target.value);
                    }} />

                    <MDBInput className="input" label='Passwort wiederholen' color="primary" id='form1' type='password' onChange={(event) => {
                        setPasswordAgain(event.target.value);
                    }} />

                    <PasswordChecklist
                        style={{marginTop: "-20px", marginBottom: "32px", marginLeft: "10px"}}
                        rules={["minLength","capital","number","match"]}
                        minLength={8}
                        value={password}
                        valueAgain={passwordAgain}
                        onChange={(isValid) => {if (isValid) {setSaveEnabled(true)}}}
                        validColor={"#64df2b"}
                        invalidColor={"#eb1515"}
                        iconSize={16}
                        messages={{
                            minLength: "Das Passwort enthält mindestens 8 Zeichen.",
                            number: "Das Passwort enthält eine Zahl.",
                            capital: "Das Passwort enthält einen Großbuchstaben.",
                            match: "Die Passwörter stimmen überein.",
                        }}
                    />
                </div>

                <div className="footer left">
                    <MDBBtn className="btn" onClick={() => {navigate("/edit-user/"+String(id))}}>
                        Abbrechen
                    </MDBBtn>
                </div>

                <div className="footer">
                    <MDBBtn className="btn" type="submit" disabled={!saveEnabled} onClick={editPassword}>Speichern</MDBBtn>
                </div>

            </div>
        </>
    );
}

export default EditPassword;