import React, {useEffect, useState} from 'react';
import Axios from "axios";
import {backendPathAuth} from "../Constants";
import TopNavbar from "../components/Navbar";
import {useNavigate, useParams} from "react-router-dom";
import {MDBBtn} from "mdb-react-ui-kit";


function NoDir() {
    const { path } = useParams();
    const navigate = useNavigate();

    const [writePermission, setWritePermission] = useState(false);
    const [readPermission, setReadPermission] = useState(false);


    const loadPermissions = () => {
        Axios.get(`${backendPathAuth}user-permissions.php?`).then((response) => {
            console.log(response.data);
            setWritePermission(response.data === "write");
            setReadPermission(response.data === "read" || response.data === "write");
        });
    }

    useEffect(() => {
        loadPermissions();
    }, []);

    return (
        <>
            <TopNavbar readPermissionInitial={readPermission} writePermissionInitial={writePermission}/>

            <div className="main">
                <p>{"Es wurde kein Ordner mit dem Namen " + path.replaceAll("_","/") + " gefunden."}</p>
                <div style={{marginTop: "1rem"}}>
                    <MDBBtn onClick={() => {navigate("/storage/root")}}>zur Übersicht</MDBBtn>
                </div>
            </div>
        </>
    );
}

export default NoDir;