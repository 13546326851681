import React, {useEffect, useRef, useState} from 'react';
import Uploader from "../components/Uploader";
import Axios from "axios";
import {backendPathAuth, backendPathStoring} from "../Constants";
import {
    MDBBtn, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle,
    MDBInput,
    MDBModal,
    MDBModalBody,
    MDBModalContent,
    MDBModalDialog,
    MDBModalFooter,
    MDBModalHeader,
    MDBModalTitle,
    MDBTable,
    MDBTableBody,
    MDBTableHead
} from "mdb-react-ui-kit";
import {FaRegFile, FaRegFolder} from "react-icons/fa";
import {Link, useNavigate, useParams} from "react-router-dom";
import {FiDownload} from "react-icons/fi";
import * as PropTypes from "prop-types";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import TopNavbar from "../components/Navbar";
import {SlOptions, SlOptionsVertical} from "react-icons/sl";
import logo from "../imgs/logo.png";

function ModalContent(props) {
    return null;
}

ModalContent.propTypes = {children: PropTypes.node};

function FileTable() {
    const { path } = useParams();
    const navigate = useNavigate();

    const [files, setFiles] = useState([]);
    const [newDirName, setNewDirName] = useState("");

    const [writePermission, setWritePermission] = useState(false);
    const [readPermission, setReadPermission] = useState(false);

    const [renameDirName, setRenameDirName] = useState("");
    const [dirToRename, setDirToRename] = useState("");

    const [fileToDelete, setFileToDelete] = useState("");
    const [dirToDelete, setDirToDelete] = useState("");

    const [creationPopupVisible, setCreationPopupVisible] = useState(false);
    const [deleteDirPopupVisible, setDeleteDirPopupVisible] = useState(false);
    const [deleteFilePopupVisible, setDeleteFilePopupVisible] = useState(false);
    const [renameDirPopupVisible, setRenameDirPopupVisible] = useState(false);


    const loadFiles = () => {
        console.log("before read ",path);
        const rePath = rebuildPath();
        if (rePath === "") {
            return;
        }
        Axios.get(`${backendPathStoring}read-files.php?path=${rePath}`).then((response) => {
            if (typeof response.data === "string") {
                navigate("/no-dir/" + path);
            }
            setFiles(response.data);
            console.log(response.data);
        });
    }

    const loadPermissions = () => {
        Axios.get(`${backendPathAuth}user-permissions.php?`).then((response) => {
            console.log(response.data);
            if (response.data !== "write" && response.data !== "read") {
                navigate("/login");
            }
            setWritePermission(response.data === "write");
            setReadPermission(response.data === "read" || response.data === "write");
        }).catch(() => { navigate("/login"); });
    }

    const addDirectory = () => {
        console.log(newDirName, path);
        Axios.get(`${backendPathStoring}new-dir.php?name=${newDirName}&path=${path}`).then((response) => {
            console.log(response.data);
            loadFiles();
        });
    }

    const deleteDirectory = () => {
        setDeleteDirPopupVisible(false);
        console.log(dirToDelete);
        Axios.get(`${backendPathStoring}delete-dir.php?name=${dirToDelete}&path=${path}`).then((response) => {
            console.log(response.data);
            loadFiles();
        });
    }

    const deleteFile = () => {
        setDeleteFilePopupVisible(false);
        console.log(fileToDelete);
        Axios.get(`${backendPathStoring}delete-file.php?name=${fileToDelete}&path=${path}`).then((response) => {
            console.log(response.data);
            loadFiles();
        });
    }

    const renameDirectory = () => {
        setDeleteDirPopupVisible(false);
        console.log(dirToDelete);
        Axios.get(`${backendPathStoring}rename-dir.php?`, {
            params: {
                oldName: dirToRename,
                newName: renameDirName,
                path: path
            }
        }).then((response) => {
            console.log(response.data);
            loadFiles();
        });
    }

    function compareFiles(file1, file2) {
        if (file1.type === "dir" && file2.type === "file") {
            return -1;
        }
        if (file2.type === "dir" && file1.type === "file") {
            return 1;
        }
        if (file1.name.toLowerCase() > file2.name.toLowerCase()) {
            return 1;
        }
        return -1;
    }

    function rebuildPath() {
        if (path === undefined) {
            return "";
        }
        return path.replaceAll("_","/");
    }

    function currentPathWithoutRoot(currentPath) {
        return currentPath.replace("root_","").replace("root/","").replace("root","");
    }

    function clickedDirectory(dirName) {

    }

    function clickedDownload(fileName) {
        Axios.get(`${backendPathStoring}download-file.php?path=${path}&name=${fileName}`, {responseType: "blob"}).then((response) => {
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', fileName); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        });
    }

    function onBackButtonEvent() {
        console.log("back from ", path);
        //console.log("back to ", currentPath.split("/").splice(-1).join("/"));
    }



    useEffect(() => {
        console.log("effect 0");
        console.log("current path =", path)
        window.onpopstate = onBackButtonEvent;
        loadPermissions();
    }, []);

    useEffect(() => {
        loadFiles();
    }, [path]);


    return (
        <>

            <TopNavbar readPermissionInitial={readPermission} writePermissionInitial={writePermission}/>

            <div className='main'>

                <div className="header">
                    <h2>Dateiablage {currentPathWithoutRoot(rebuildPath())}</h2>
                    <Uploader hidden={!writePermission} refresh={loadFiles} onClick={loadFiles} target={rebuildPath()}></Uploader>
                    <div style={{marginLeft: "1rem"}}>
                        <MDBBtn hidden={!writePermission} onClick={() => {setCreationPopupVisible(true); console.log("new folder dialog");}}>neuer Ordner</MDBBtn>
                    </div>

                </div>

                {
                    files.length > 0 ?
                        <MDBTable align='middle'>
                            <MDBTableHead className="table-head">
                                <tr className="fixed-header">
                                    <th></th>
                                    <th scope='col'>Name</th>
                                    <th scope='col'>Stand</th>
                                    <th></th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {
                                    files
                                        .sort(compareFiles)
                                        .map((val, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>
                                                        {
                                                            val.type === "dir"? <FaRegFolder size={"1.25rem"}/> : <FaRegFile size={"1.2rem"}/>
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            val.type === "dir"?
                                                                <Link to={"/storage/"+path+"_"+val.name} onClick={() => {clickedDirectory(val.name)}}>{val.name}</Link>
                                                                :
                                                                val.name
                                                        }
                                                    </td>
                                                    <td>{val.date}</td>
                                                    <td>
                                                        <div  style={{float: "right"}}>
                                                            <button hidden={writePermission || val.type === "dir"} className="download-btn" onClick={() => clickedDownload(val.name)}>
                                                                <FiDownload size={18}/>
                                                            </button>

                                                            <MDBDropdown dropright group hidden={!writePermission}>
                                                                <MDBDropdownToggle className="options-btn">
                                                                    <button className="download-btn">
                                                                        <SlOptions size={18}/>
                                                                    </button>
                                                                </MDBDropdownToggle>
                                                                <MDBDropdownMenu>
                                                                    <MDBDropdownItem link hidden={val.type !== "dir"} onClick={() => {
                                                                        setDirToRename(val.name);
                                                                        setRenameDirName(val.name);
                                                                        setRenameDirPopupVisible(true)}
                                                                    }>
                                                                        Umbenennen
                                                                    </MDBDropdownItem>
                                                                    <MDBDropdownItem link hidden={val.type === "dir"} onClick={() => clickedDownload(val.name)}>
                                                                        Herunterladen
                                                                    </MDBDropdownItem>
                                                                    <MDBDropdownItem link onClick={() => {
                                                                        console.log(val.type);
                                                                        if (val.type === "dir") {
                                                                            console.log("why");
                                                                            setDirToDelete(val.name);
                                                                            setDeleteDirPopupVisible(true)
                                                                        } else {
                                                                            console.log("there");
                                                                            setFileToDelete(val.name);
                                                                            setDeleteFilePopupVisible(true);
                                                                        }
                                                                    }}>
                                                                        Löschen
                                                                    </MDBDropdownItem>
                                                                </MDBDropdownMenu>
                                                            </MDBDropdown>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                }
                            </MDBTableBody>
                        </MDBTable>
                        :
                        <MDBTable align='middle'>
                            <MDBTableHead className="table-head">
                                <tr className="fixed-header">
                                    <th></th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                <tr>
                                    <td style={{textAlign:"center"}}>
                                        Dieser Ordner ist noch leer
                                    </td>
                                </tr>
                            </MDBTableBody>
                        </MDBTable>
                }

                <MDBModal open={creationPopupVisible} tabIndex="-1">
                    <MDBModalDialog>
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle>Neuen Ordner hinzufügen</MDBModalTitle>
                                <MDBBtn
                                    className="btn-close"
                                    color="none"
                                    onClick={() => {setCreationPopupVisible(!creationPopupVisible)}}
                                ></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                <MDBInput className="input" label='Ordnername' type='text' onChange={(event) => {
                                    setNewDirName(event.target.value);
                                }} />
                            </MDBModalBody>

                            <MDBModalFooter>
                                <MDBBtn disabled={newDirName === ""} onClick={() => {
                                    setCreationPopupVisible(false);
                                    addDirectory();
                                }}>
                                    Hinzufügen
                                </MDBBtn>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>


                <MDBModal open={renameDirPopupVisible} tabIndex="-1">
                    <MDBModalDialog>
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle>Ordner {dirToRename} umbenennen</MDBModalTitle>
                                <MDBBtn
                                    className="btn-close"
                                    color="none"
                                    onClick={() => {setRenameDirPopupVisible(!renameDirPopupVisible)}}
                                ></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                <MDBInput className="input" label='neuer Ordnername' value={renameDirName} type='text' onChange={(event) => {
                                    setRenameDirName(event.target.value);
                                }} />
                            </MDBModalBody>

                            <MDBModalFooter>
                                <MDBBtn disabled={renameDirName === ""} onClick={() => {
                                    setRenameDirPopupVisible(false);
                                    renameDirectory();
                                }}>
                                    Umbenennen
                                </MDBBtn>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>


                <MDBModal open={deleteDirPopupVisible} tabIndex="-1">
                    <MDBModalDialog>
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle>Ordner löschen</MDBModalTitle>
                                <MDBBtn
                                    className="btn-close"
                                    color="none"
                                    onClick={() => {setDeleteDirPopupVisible(!deleteDirPopupVisible)}}
                                ></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                Soll der Ordner <b>{dirToDelete}</b> mit allen darin enthaltenen Ordnern und Dateien wirklich gelöscht werden?
                            </MDBModalBody>

                            <MDBModalFooter>
                                <MDBBtn className="btn-light" onClick={() => {setDeleteDirPopupVisible(!deleteDirPopupVisible)}}>
                                    Abbrechen
                                </MDBBtn>
                                <MDBBtn onClick={deleteDirectory}>Löschen</MDBBtn>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>

                <MDBModal open={deleteFilePopupVisible} tabIndex="-1">
                    <MDBModalDialog>
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle>Datei löschen</MDBModalTitle>
                                <MDBBtn
                                    className="btn-close"
                                    color="none"
                                    onClick={() => {setDeleteFilePopupVisible(!deleteFilePopupVisible)}}
                                ></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                Soll die Datei <b>{fileToDelete}</b> wirklich gelöscht werden?
                            </MDBModalBody>

                            <MDBModalFooter>
                                <MDBBtn className="btn-light" onClick={() => {setDeleteFilePopupVisible(!deleteFilePopupVisible)}}>
                                    Abbrechen
                                </MDBBtn>
                                <MDBBtn onClick={deleteFile}>Löschen</MDBBtn>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>

            </div>
        </>
    );
}

export default FileTable;