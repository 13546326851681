import React, { useState, useEffect } from "react";
import "../App.css";

import Axios from "axios";
import Select from 'react-select'
import {useNavigate, useParams, useSearchParams} from "react-router-dom";

import {MDBTextArea, MDBBtn, MDBInput} from "mdb-react-ui-kit";
import {backendPathAuth, backendPathUsers, permissionTypes} from "../Constants";
import TopNavbar from "../components/Navbar";
import logo from "../imgs/logo.png";




function EditUser() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [permissions, setPermissions] = useState({value: 0, label: "Dateien ansehen"});
    const [username, setUsername] = useState("");
    const [saveEnabled, setSaveEnabled] = useState(true);

    const [writePermission, setWritePermission] = useState(false);
    const [readPermission, setReadPermission] = useState(false);


    const checkWritePermission = () => {
        Axios.get(`${backendPathAuth}user-permissions.php?`).then((response) => {
            console.log(response.data);
            if (response.data !== "write") {
                navigate("/login");
            }
            setReadPermission(response.data === "read" || response.data === "write");
            setWritePermission(response.data === "write");
        }).catch(() => { navigate("/login"); });
    }

    const getUser = () => {
        Axios.get(backendPathUsers+"user.php?id="+String(id)).then((response) => {
            if (response.data) {
                if (response.data === "unauthorized") {
                    navigate("/login");
                } else if (response.data.length === 1) {
                    setUsername(response.data[0]["Username"]);
                    setPermissions(permissionTypes[response.data[0]["EditPermissions"]]);
                } else {
                    navigate("/users");
                }
                console.log(response.data)
            }
        });
    };

    const editUser = () => {
        Axios.get(backendPathUsers+"update-user.php", {
            params: {
                id: id,
                username: username,
                permissions: permissions.value,
            }
        }).then(() => {
            navigate("/users");
        });
    };

    const validateTask = () => {

        if (!checkCompleteness()) {
            setSaveEnabled(false);
            return;
        }

        editUser();
    }

    const checkCompleteness = () => {
        if (username === "") {
            console.log("not complete");
            return false;
        }
        return true;
    };


    useEffect(() => {
        checkWritePermission();
    }, []);

    useEffect(() => {
        getUser();
    }, [id]);

    return (
        <>

            <TopNavbar readPermissionInitial={readPermission} writePermissionInitial={writePermission}/>

            <div className="main">
                <div className="header">
                    <h2>Benutzer Account bearbeiten</h2>
                </div>
                
                <div className='input-form'>
                    <MDBInput className="input" label='Username' color="primary" id='form1' type='text' value={username} onChange={(event) => {
                        setUsername(event.target.value);
                        if (event.target.value === "") {
                            setSaveEnabled(false);
                        } else if (event.target.value !== "" && !saveEnabled) {
                            setSaveEnabled(true);
                        }
                    }} />
                    {(username === "")?
                        <p className="validation-label">Username darf nicht leer sein.</p> : null
                    }

                    <p className="input-label">Nutzerrechte</p>
                    <Select className="input" placeholder={'Auswählen...'} options={permissionTypes} value={permissions} onChange={(event) => {
                        setPermissions({value: event.value, label: event.label});
                    }} />
                </div>

                <div className="footer">
                    <MDBBtn className="btn" disabled={!saveEnabled} onClick={validateTask}>Speichern</MDBBtn>
                </div>

                <div className="footer left">
                    <MDBBtn className="btn" type="submit" disabled={!saveEnabled} onClick={() => {navigate("/edit-password/"+String(id))}}>
                        Passwort ändern
                    </MDBBtn>
                </div>

            </div>
        </>
    );
}

export default EditUser;