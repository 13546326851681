import React, { useState, useEffect } from "react";
import "../App.css";

import Axios from "axios";

import {
    MDBBtn,
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBInput,
    MDBModalFooter, MDBModal,
} from "mdb-react-ui-kit";
import {Link, useNavigate} from "react-router-dom";

import {
    backendPathAuth,
    backendPathUsers,
} from "../Constants";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import TopNavbar from "../components/Navbar";
import {FiDownload} from "react-icons/fi";
import {FaPen, FaTrash} from "react-icons/fa";
import logo from "../imgs/logo.png";


function Users() {
    const navigate = useNavigate();

    const [userList, setUserList] = useState([]);
    const [userToDelete, setUserToDelete] = useState({id: -1, name: ""});
    const [deletionPopupVisible, setDeletionPopupVisible] = useState(false);

    const [writePermission, setWritePermission] = useState(false);
    const [readPermission, setReadPermission] = useState(false);


    const checkWritePermission = () => {
        Axios.get(`${backendPathAuth}user-permissions.php?`).then((response) => {
            console.log(response.data);
            if (response.data !== "write") {
                navigate("/login");
            }
            setReadPermission(response.data === "read" || response.data === "write");
            setWritePermission(response.data === "write");
        }).catch(() => { navigate("/login"); });
    }

    const getUsers = () => {
        Axios.get(backendPathUsers+"users.php").then((response) => {
            if (response.data) {
                if (response.data === "unauthorized") {
                    navigate("/login");
                } else {
                    setUserList(response.data);
                }
                console.log(response.data)
            }
        });
    };

    const deleteTask = () => {
        Axios.delete(`${backendPathUsers}delete-user.php?id=${userToDelete.id}`).then((response) => {
            getUsers();
        });
        setUserToDelete({id:-1, name:""});
        setDeletionPopupVisible(false);
    };

    const toggleShow = () => setDeletionPopupVisible(!deletionPopupVisible);

    useEffect(() => {
        checkWritePermission();
    }, []);

    useEffect(() => {
        getUsers();
    }, [writePermission]);


    return (
        <>

            <TopNavbar readPermissionInitial={readPermission} writePermissionInitial={writePermission}/>

            <div className="main">
                <div className="header">
                    <h2>Benutzer Accounts</h2>
                    <Link className="header-btn" to="/new-user">
                        <MDBBtn style={{color: 'white'}} className="btn" onClick={() => {navigate("/new-user")}}>Hinzufügen</MDBBtn>
                    </Link>
                </div>

                <MDBTable align='middle'>
                    <MDBTableHead className="table-head">
                        <tr>
                            <th scope='col'>Username</th>
                            <th scope='col'>Nutzerrechte</th>
                            <th></th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {userList
                            .map((val, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{val["Username"]}</td>
                                        <td>{val["EditPermissions"] === '0' ? "Dateien ansehen" : "Dateien und Accounts bearbeiten"}</td>
                                        <td>
                                            <div style={{float: "right"}}>
                                                <button className="edit-btn me-4" onClick={() => {
                                                    navigate("/edit-user/"+String(val["UserID"]));
                                                }}>
                                                    <FaPen size={18}/>
                                                </button>
                                                <button className="edit-btn" onClick={() => {
                                                    setUserToDelete({id: val["UserID"], name: val["Username"]});
                                                    setDeletionPopupVisible(true);
                                                    console.log("del");
                                                }
                                                }>
                                                    <FaTrash size={18}/>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                    </MDBTableBody>
                </MDBTable>

                <MDBModal open={deletionPopupVisible} tabIndex="-1">
                    <MDBModalDialog>
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle>Account löschen</MDBModalTitle>
                                <MDBBtn
                                    className="btn-close"
                                    color="none"
                                    onClick={toggleShow}
                                ></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                Soll der Account von <b>{userToDelete.name}</b> wirklich gelöscht werden?
                            </MDBModalBody>

                            <MDBModalFooter>
                                <MDBBtn className="btn-light" onClick={toggleShow}>
                                    Abbrechen
                                </MDBBtn>
                                <MDBBtn onClick={deleteTask}>Löschen</MDBBtn>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>

            </div>
        </>
    );
}

export default Users;